import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import 'nes.css/css/nes.min.css';
import './styles.css';

const App = () => {
  const [currentTime, setCurrentTime] = useState('');
  const [isMuted, setIsMuted] = useState(true); 
  const [metadata, setMetadata] = useState({ title: '', artist: '' }); 
  const audioRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const estTime = moment().tz('America/New_York').format('hh:mm:ss A');
      setCurrentTime(estTime);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {
      
        }).catch(error => {
          // Auto-play was prevented
          console.error("Auto-play prevented", error);
        });
      }
      // Set initial mute state
      audioRef.current.muted = isMuted;
    }
  }, [isMuted]);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await fetch('https://stream.data-roaming.net/status-json.xsl');
        const data = await response.json();
        const stream = Array.isArray(data.icestats.source) ? data.icestats.source[0] : data.icestats.source;
        setMetadata({ title: stream.title });
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };

    fetchMetadata(); // Initial fetch
    const intervalId = setInterval(fetchMetadata, 5000); // Fetch metadata every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const handleMuteUnmute = () => {
    if (audioRef.current) {
      audioRef.current.muted = !audioRef.current.muted;
      setIsMuted(audioRef.current.muted);
    }
  };

  return (
    <div className="app-container">
      <h1 className="dataRoaming">DATA ROAMING</h1>
      <p className="clockTime">{currentTime}</p>
      <div className="content-container">
        <div className="nes-container is-rounded is-dark custom-box">
          <audio ref={audioRef} className="hidden-audio">
            <source src="https://stream.data-roaming.net/stream.mp3" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          {metadata.title && <p className="title">{metadata.title}</p>}
        </div>
        <div className="mute-button-container">
          <button type="button" className="nes-btn" onClick={handleMuteUnmute}>
            {isMuted ? 'Start' : 'Stop'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default App;








